<template>
  <v-main class="grey lighten-4">
    <router-view />
    <dashboard-core-footer v-if="this.$route.name !== 'Mapboard' && this.$route.name !== 'Form build'" />
  </v-main>
</template>

<script>
  export default {
    name: 'DashboardCoreView',

    components: {
      DashboardCoreFooter: () => import('./Footer'),
    },
  }
</script>
